<style>
.input-readonly>div>.vs-inputx {
  background-color: #e6e6e6;
}

.bg-dark {
  background-color: #e6e6e6 !important;
}

.bg-green {
  background-color: #06fc27 !important;
}

.fullpage-div {
  height: 80vh;
  /* background-color:#FFFFFF; */
}

.div-scroll {
  /* background-color: lightblue; */
  /* max-width: 150px; */
  height: 80%;
  overflow: auto;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div>
      <div v-if="listRecon.length > 0">
        <vs-button type="gradient" color="danger" v-on:click="handleReconcile()">Reconcile</vs-button>
        <vs-divider style="width:100%;margin-left:2%"></vs-divider>
        Transaction Value : {{ priceFormat(this.selected.TransactionValue) }}<br>
        Selected Value : {{ priceFormat(this.totalSelected) }}<br>
        Remaining Value : {{ priceFormat(parseFloat(this.selected.TransactionValue) - parseFloat(this.totalSelected))}}
        <vs-divider style="width:100%;margin-left:2%">List Reconcile</vs-divider>
        <TransitionGroup name="list" tag="ul">
          <li v-for="item in detailListRecon" :key="item">
            <vs-button type="line" icon-pack="feather" icon="icon-trash" v-on:click.stop="unReconcile(item.type, item.row)" />
            {{
            item.type == 'payment' ? 'Payment' : (item.type == 'cash-deposit' ? 'Cash Deposit' : (item.type ==
            'customer-deposit' ? 'Customer Deposit' : 'Bank Statement'))
            }}<br>
            <span v-if="item.type == 'cash-deposit'">
              Method : {{ methods[item.row.Method]}}<br>
              Ref Code : {{ item.row.ReferenceCode }}<br>
              Deposit Number : {{ item.row.DepositNumber }}<br>
              Deposit Value : {{ priceFormat(item.row.DepositValue) }}<br>
              Bank : {{ item.row.BankName }}<br>
              Account Name : {{ item.row.AccountName }}<br>
              Account Number : {{ item.row.AccountNumber }}<br>
              <template v-if="item.row.GiroBankID != 0">
                Customer : {{ '(' + item.row.PersonCode + ') ' + item.row.PersonName }} <br>
                {{ methods[item.row.Method]}} Bank : {{ item.row.GiroBankName }}<br>
                {{ methods[item.row.Method]}} Number : {{ item.row.GiroNumber }}<br>
                {{ methods[item.row.Method]}} Date : {{ dateFormat(item.row.GiroDate) }}<br>
              </template>
            </span>
            <span v-if="item.type == 'customer-deposit'">
              Method : {{ methods[item.row.Method]}}<br>
              Ref Code : {{ item.row.ReferenceCode }}<br>
              Deposit Number : {{ item.row.DepositNumber }}<br>
              Deposit Value : {{ priceFormat(item.row.DepositValue) }}<br>
              Bank : {{ item.row.BankName }}<br>
              Account Name : {{ item.row.AccountName }}<br>
              Account Number : {{ item.row.AccountNumber }}<br>
              Customer : {{ '(' + item.row.PersonCode + ') ' + item.row.PersonName }} <br>
              <template v-if="item.row.GiroBankID != 0">
                {{ methods[item.row.Method]}} Bank : {{ item.row.GiroBankName }}<br>
                {{ methods[item.row.Method]}} Number : {{ item.row.GiroNumber }}<br>
                {{ methods[item.row.Method]}} Date : {{ dateFormat(item.row.GiroDate) }}<br>
              </template>
            </span>
            <span v-if="item.type == 'payment'">
              Method : {{ item.row.PaymentMethod }}<br>
              Ref Code : {{ item.row.ReferenceCode }}<br>
        
              Payment Value : {{ priceFormat(item.row.Amount) }}<br>
              <template v-if="item.row.GiroBankID != 0">
                Customer : {{ '(' + item.row.CustomerCode + ') ' + item.row.CustomerName }} <br>
                Bank : {{ item.row.BankName }} ({{ item.row.BankAccountNumber }} - {{ item.row.BankAccountName }})<br>
              </template>
            </span>
            <span v-if="item.type == 'bank-statement'">
              Branch Code : {{ item.row.BranchCode }} <br>
              Statement Code :{{ item.row.BankStatement.Code }}<br />
              Payment Value : {{ priceFormat(item.row.TransactionValue) }}<br>
              Name VA : {{ item.row.NameVa }} <br>
              Reference Code : {{ item.row.ReferenceCode ? item.row.ReferenceCode : "-" }}<br />
              No Giro : {{ item.row.NoGiro ? item.row.NoGiro : "-" }}<br />
              Remark : {{ item.row.Note }}<br />
              Txn Date : {{ dateFormat(item.row.TxnDate) }}<br />
              <template v-if="item.row.CashBankID != 0">
                Cash Bank Code : {{ item.row.CashBankCode }}
              </template>
        
            </span>
            <span v-if="type == 'supplier-payment'">
              Code : {{ item.row.Code }}<br />
              No Giro : {{ item.row.GiroNumber ? item.row.GiroNumber : "-" }}<br />
              Supplier : {{('(' + item.row.PartnerCode + ') ' + item.row.PartnerName)}} <br>
              Bank : {{ item.row.BankName }} ({{ item.row.BankAccountNumber }} - {{ item.row.BankAccountName }})<br>
              Payment Value : {{ priceFormat(item.row.Amount) }}<br>
        
            </span>
            <vs-divider style="width:100%;margin-left:2%"></vs-divider>
          </li>
        </TransitionGroup>
      </div>

    </div>
    <div class="vx-col w-full">
      <template v-if="selected.TransactionValue >= 0">
        <vs-radio v-model="type" vs-value="cash-deposit" class="mr-4" vs-name="type">Cash
          Deposit</vs-radio>
        <vs-radio v-model="type" vs-value="customer-deposit" class="mr-4" vs-name="type">Customer
          Deposit</vs-radio>
        <vs-radio v-model="type" vs-value="payment" class="mr-4" vs-name="type">Transfer /
          Cheque</vs-radio>
        <vs-radio v-model="type" vs-value="supplier-payment" class="mr-4" vs-name="type">Supplier Payment</vs-radio>
      </template>
      <template v-else>
        <vs-radio v-model="type" vs-value="bank-statement" class="mr-4" vs-name="type">Bank
          Statement</vs-radio>
      </template>
    </div>
    <vs-popup title="" :active.sync="detail">
      <div class="vx-row mb-6">
        <div class="vx-col w-full">

          <div class="fullpage-div">
            <div class="div-scroll">
              <template>
                <vs-card v-bind:key="indextr" v-for="(invoice, indextr) in invoices.CashBankManagementLine">
                  <div slot="header">
                    <h6>
                      Customer : {{ invoice.Payment.CustomerName }} ({{ invoice.Payment.CustomerCode }}) <br>
                      Invoice : {{ invoice.Payment.PaymentLine[0].InvoiceCode }}<br>
                      Number : {{ invoice.PaymentNumber }} <br>
                      Method : {{ invoice.PaymentMethod }} <br>
                      Value : {{ priceFormat(invoice.PaymentValue) }} <br>
                    </h6>
                  </div>
                  <div>
                  </div>
                </vs-card>
              </template>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- <vs-divider style="width:100%;margin-left:2%">Suggestion</vs-divider>
    <div v-if="suggestion.length < 1" class="vx-col w-full">
      <vs-alert color="danger">
        Not Found Suggestion
      </vs-alert>
    </div>
    <div v-else>
      <vs-card v-bind:key="indextr" v-for="(tr,indextr) in suggestion">
        <div slot="header">
          <h6>
            {{tr.Code}}
          </h6>
        </div>
        <div>
          <span>
            Method : {{methods[tr.Method]}}<br>
            Ref Code : {{tr.ReferenceCode}}<br>
            <template v-if="tr.GiroBankID!=0">
              Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName}} <br>
              {{methods[tr.Method]}} Bank : {{tr.GiroBankName}}<br>
              {{methods[tr.Method]}} Number : {{tr.GiroNumber}}<br>
              {{methods[tr.Method]}} Date : {{dateFormat(tr.GiroDate)}}<br>
            </template>
          </span>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button type="gradient" color="danger" v-on:click="handleReconcile(tr)">reconcile</vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div> -->
    <vs-divider style="width:100%;margin-left:2%">Option</vs-divider>
    <div class="vx-col w-full">
      <card-data-table :responseData="responseData" :propsParams="params" @reloadDataFromChild="reloadData">
        <template slot="tbody">
          <vs-card v-bind:key="indextr" v-for="(tr, indextr) in optionOther">
            <div slot="header">
              <div class="vx-row" style="padding-left:10px">
                <div style="width:80%;">
                  <h6 style="left-margin:1%">
                    {{ tr.Code }}
                  </h6>
                </div>
              </div>
            </div>
            <div>

              <span v-if="type == 'cash-deposit'">
                Method : {{ methods[tr.Method]}}<br>
                Ref Code : {{ tr.ReferenceCode }}<br>
                Deposit Number : {{ tr.DepositNumber }}<br>
                Deposit Value : {{ priceFormat(tr.DepositValue) }}<br>
                Bank : {{ tr.BankName }}<br>
                Account Name : {{ tr.AccountName }}<br>
                Account Number : {{ tr.AccountNumber }}<br>
                <!-- {{tr.CashBankManagementLine}} -->
                <template v-if="tr.GiroBankID != 0">
                  Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName }} <br>
                  {{ methods[tr.Method]}} Bank : {{ tr.GiroBankName }}<br>
                  {{ methods[tr.Method]}} Number : {{ tr.GiroNumber }}<br>
                  {{ methods[tr.Method]}} Date : {{ dateFormat(tr.GiroDate) }}<br>
                </template>
                <!-- <vx-tooltip text="Detail Mapping" class="mr-4"  >
                  <vs-button
                  v-on:click="showDetail(tr)"
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                  />
                </vx-tooltip> -->
              </span>
              <span v-if="type == 'customer-deposit'">
                Method : {{ methods[tr.Method]}}<br>
                Ref Code : {{ tr.ReferenceCode }}<br>
                Deposit Number : {{ tr.DepositNumber }}<br>
                Deposit Value : {{ priceFormat(tr.DepositValue) }}<br>
                Bank : {{ tr.BankName }}<br>
                Account Name : {{ tr.AccountName }}<br>
                Account Number : {{ tr.AccountNumber }}<br>
                Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName }} <br>
                <!-- {{tr.CashBankManagementLine}} -->
                <template v-if="tr.GiroBankID != 0">
                  {{ methods[tr.Method]}} Bank : {{ tr.GiroBankName }}<br>
                  {{ methods[tr.Method]}} Number : {{ tr.GiroNumber }}<br>
                  {{ methods[tr.Method]}} Date : {{ dateFormat(tr.GiroDate) }}<br>
                </template>
                <!-- <vx-tooltip text="Detail Mapping" class="mr-4"  >
                  <vs-button
                  v-on:click="showDetail(tr)"
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                  />
                </vx-tooltip> -->
              </span>
              <span v-if="type == 'payment'">
                Method : {{ tr.PaymentMethod }}<br>
                Ref Code : {{ tr.ReferenceCode }}<br>

                Payment Value : {{ priceFormat(tr.Amount) }}<br>
                <template v-if="tr.GiroBankID != 0">
                  Customer : {{ '(' + tr.CustomerCode + ') ' + tr.CustomerName }} <br>
                  Bank : {{ tr.BankName }} ({{ tr.BankAccountNumber }} - {{ tr.BankAccountName }})<br>
                </template>
              </span>
              <span v-if="type == 'bank-statement'">
                Branch Code : {{ tr.BranchCode }} <br>
                Statement Code :{{ tr.BankStatement.Code }}<br />
                Payment Value : {{ priceFormat(tr.TransactionValue) }}<br>
                Name VA : {{ tr.NameVa }} <br>
                Reference Code : {{ tr.ReferenceCode ? tr.ReferenceCode : "-" }}<br />
                No Giro : {{ tr.NoGiro ? tr.NoGiro : "-" }}<br />
                Remark : {{ tr.Note }}<br />
                Txn Date : {{ dateFormat(tr.TxnDate) }}<br />

              </span>
              <span v-if="type == 'supplier-payment'">
                Code : {{ tr.Code }}<br />
                No Giro : {{ tr.GiroNumber ? tr.GiroNumber : "-" }}<br />
                Supplier : {{('(' + tr.PartnerCode + ') ' + tr.PartnerName)}} <br>
                Bank : {{ tr.BankName }} ({{ tr.BankAccountNumber }} - {{ tr.BankAccountName }})<br>
                Payment Value : {{ priceFormat(tr.Amount) }}<br>

              </span>
            </div>
            <div slot="footer">
              <vs-row vs-justify="flex-end">
                <transition mode="out-in">

                  <vs-button v-if="!listRecon.includes(type + tr.ID)" type="gradient" color="danger"
                    v-on:click="addReconcile(type, tr)">reconcile</vs-button>
                  <vs-button v-else type="gradient" color="success" v-on:click="unReconcile(type, tr)">cancel
                    reconcile</vs-button>
                </transition>
              </vs-row>
            </div>
          </vs-card>
        </template>
      </card-data-table>
      <!-- <vs-button v-if="other.length < optionOther.length " color="primary" type="filled" v-on:click="loadMore" >Load More </vs-button> -->

    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  created() { },
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
    operatingUnitText: String,
  },
  methods: {
    initialState() {
      return {
        totalSelected: 0,
        listRecon: [],
        detailListRecon: [],
        responseData: {},
        detail: false,
        params: {
          search: "",
          length: 10,
          page: 1,
          order: "desc",
          sort: "id",
        },
        methods: ['', 'Cash', 'Giro', 'Check', 'Transfer'],
        suggestion: [],
        type: 'cash-deposit',
        suggestID: [],
        other: [],
        optionOther: [],
        invoices: [],
      };
    },
    showDetail(data) {
      this.invoices = data
      this.detail = 1
    },
    formatPrice(angka) {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    unReconcile(type, row) {
      if (type == 'payment') {
        this.totalSelected -= row.Amount
      } else if (type == 'cash-deposit') {
        this.totalSelected -= row.DepositValue
      } else if (type == 'customer-deposit') {
        this.totalSelected -= row.DepositValue
      } else if (type == 'bank-statement') {
        this.totalSelected -= row.TransactionValue
      } else if (this.type == 'supplier-payment') {
        this.totalSelected -= row.Amount
      }
      this.listRecon.splice(this.listRecon.indexOf(type + row.ID.toString()), 1);
      this.detailListRecon.splice(this.listRecon.indexOf(type + row.ID.toString()), 1);
    },
    addReconcile(type, row) {
      let array = {
        type: type,
        id: row.ID,
        row: row
      }
      if (type == 'payment') {
        this.totalSelected += row.Amount
      } else if (type == 'cash-deposit') {
        this.totalSelected += row.DepositValue
      } else if (type == 'customer-deposit') {
        this.totalSelected += row.DepositValue
      } else if (type == 'bank-statement') {
        this.totalSelected += row.TransactionValue
      } else if (this.type == 'supplier-payment') {
        this.totalSelected += row.Amount
      }

      if (this.listRecon.includes(type + row.ID.toString())) {

      } else {
        this.listRecon.push(type + row.ID.toString());
        this.detailListRecon.push(array);
      }
    },
    getSuggestion() {
      const params = {
        search: this.selected.ReferenceCode
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.suggestion = r.data.cashBank;
          this.suggestID = []
          this.suggestion.map(r => {
            this.suggestID.push(r.ID)
          })
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    handleReconcile() {
      this.$vs.loading();
      let params = {}
      let recon = []
      this.detailListRecon.map(v => {
        let payment_id = 0
        let payment_code = ''
        let cash_bank_id = 0
        let cash_bank_code = ''
        let bank_statement_id = 0
        let bank_statement_code = ''
        let supplier_payment_id = 0
        let supplier_payment_code = ''
        if (v.type == 'cash-deposit') {
          cash_bank_id = v.row.ID
          cash_bank_code = v.row.Code
        } else if (v.type == 'customer-deposit') {
          cash_bank_id = v.row.ID
          cash_bank_code = v.row.Code
        } else if (v.type == 'payment') {
          payment_id = v.row.ID
          payment_code = v.row.Code
        } else if (v.type == 'supplier-payment') {
          supplier_payment_id = v.row.ID
          supplier_payment_code = v.row.Code
        } else {
          bank_statement_id = v.row.ID
          bank_statement_code = v.row.Code
        }
        const array = {
          payment_id: payment_id,
          payment_code: payment_code,
          cash_bank_id: cash_bank_id,
          cash_bank_code: cash_bank_code,
          bank_statement_id: bank_statement_id,
          bank_statement_code: bank_statement_code,
          supplier_payment_id: supplier_payment_id,
          supplier_payment_code: supplier_payment_code,
        }
        recon.push(array)
      })
      params = {
        "reconcile_json": recon,
        "bank_statement_id": this.selected.ID,
      }


      this.$http.post('/api/v1/bank-statement/reconcile-manual', params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });

          }
        }).catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e)
        })

    },
    loadMore() {
      // this.$vs.loading();
      this.optionOther.map((r, i) => {
        if (i >= this.other.length && i <= (parseInt(this.other.length) + parseInt(10))) {
          if (!this.suggestID.includes(r.ID)) {
            this.other.push(r)
          }
        }
      })
    },
    getOther() {
      const params = {
        search: ""
      };
      console.log(params);
      this.$vs.loading();
      this.other = []
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.optionOther = r.data.cashBank;
          this.optionOther.map((r, i) => {
            if (i < 2) {
              if (!this.suggestID.includes(r.ID)) {
                this.other.push(r)
              }
            }
          })
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          if (this.type == 'cash-deposit') {
            this.optionOther = r.data.cashBank;
            this.responseData.length = r.data.cashBank.length;
          } else if (this.type == 'customer-deposit') {
            this.optionOther = r.data.cashBank;
            this.responseData.length = r.data.cashBank.length;
          } else if (this.type == 'payment') {
            this.optionOther = r.data.payment;
            this.responseData.length = r.data.payment.length;
          } else if (this.type == 'bank-statement') {
            this.optionOther = r.data.bankStatementLines;
            this.responseData.length = r.data.length;
          } else if (this.type == 'supplier-payment') {
            this.optionOther = r.data.otherPayment;
            this.responseData.length = r.data.length;
          }
          this.responseData = r.data;

          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        let path = ''
        let status = ''
        let sort = "id"
        let order = "desc"
        let type = 1
        if (this.type == 'cash-deposit') {
          path = '/cash-bank/data-table'
          status = 3
          order = "id",
          sort = "desc"
        } else if (this.type == 'customer-deposit') {
          path = '/cash-bank/data-table'
          status = 3
          type = 2
          order = "id",
          sort = "desc"
        } else if (this.type == 'payment') {
          path = '/payment/data-table'
          status = 2
          sort = "id",
          order = "desc"
        } else if (this.type == 'bank-statement') {
          path = '/bank-statement/by-bank/data-table'
          status = 100
          sort = "desc",
          order = "id"
        } else if (this.type == 'supplier-payment') {
          path = '/other-payment/for-dn/data-table'
          status = 3
          order = "desc",
          sort = "id"
        }
        this.$http.get('/api/v1' + path ,{
            params: {
                type: type,
                status: status,
                search: params.search,
                length: 10,
                page: params.page,
                order: order,
                sort: sort,
                // territory_id: this.selected.TerritoryID,
                territory_text: this.operatingUnitText,
                is_reconcile_bank_statement: true,
                bank_statement_id: this.selected.ID,
                bank_id: this.selected.BankStatement.BankID,
                account_number: this.selected.BankStatement.AccountNumber,
                transaction_value: Math.abs(this.selected.TransactionValue),
            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },

  },
  mounted() {
    console.log(this.selected)
    console.log("mounted")
    if (this.selected.ReferenceCode != "") {
      this.getSuggestion()
    }
    if (this.selected.TransactionValue < 0) {
      this.type = 'bank-statement'
      this.reloadData(this.params)
    } else if (this.selected.TransactionValue > 0) {
      this.type = 'cash-deposit'
      this.reloadData(this.params)
    }
    // this.getOther()
  },
  computed: {},
  watch: {
    "create.method"() {

    },
    "type"() {
      this.reloadData(this.params)
    },
    "operatingUnitText"() {
      console.log(this.operatingUnitText)
      console.log("unit text")
    },
    "selected.ID"() {
      this.$nextTick(() => {
        // if (this.selected.ReferenceCode != "") {
        //   this.getSuggestion()
        // } 
        if (this.selected.TransactionValue < 0) {
          this.type = 'bank-statement'
          console.log('bank-statement')
        } else {
          this.type = 'cash-deposit'
          this.reloadData(this.params)
        }

        console.log(this.selected)
      })
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>